import axios from 'axios';
import React, { useEffect, useState, useContext } from 'react';
import { useForm } from "react-hook-form";
import { Button, Col, Form, FormGroup, Input, Row } from 'reactstrap';
import withRedirect from '../../hoc/withRedirect';
import AppContext from '../../context/Context';

axios.defaults.withCredentials = true;
const Login = ({ setRedirect, setRedirectUrl }) => {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [submitDisabled, setSubmitDisabled] = useState(true);
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' });
  const [hasError, setHasError] = useState(false);
  // const {
  //   isAuth,
  //   setIsAuth
  // } = useContext(AppContext);

  useEffect(() => {
    setSubmitDisabled(!email || !password);
  }, [email, password]);

  const onSubmit = (e) => {
    axios.get(process.env.REACT_APP_API_ENDPOINT + '/sanctum/csrf-cookie').then(response => {
      console.log(response);
      axios.post(process.env.REACT_APP_API_ENDPOINT + '/login', {
        email: email,
        password: password
      }).then((response) => {
        console.log(response);
        setRedirectUrl("/dashboard");
        setRedirect(true);
      }).catch((response) => {
        console.log(response);
        setHasError(true);
      });
    }).catch((response) => {
      console.log(response);
      setHasError(true);
    });
  };

  return (
    <>
      <Row className="text-left justify-content-between">
        <Col xs="auto mb-2">
          <h5>ログイン</h5>
        </Col>
      </Row>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <FormGroup>
          <Input
            placeholder="Eメールアドレス"
            value={email}
            onChange={({ target }) => setEmail(target.value)}
            type="email"
            name="email"
          />
        </FormGroup>
        <FormGroup>
          <Input
            placeholder="パスワード"
            value={password}
            onChange={({ target }) => setPassword(target.value)}
            type="password"
            name="password"
          />
        </FormGroup>
        <FormGroup>
          <Button color="primary" block className="mt-3 mb-2" disabled={submitDisabled}>
            ログイン
          </Button>
          {hasError && <span className="text-danger">ログイン情報が正しくありません</span>}
          <br />
        </FormGroup>
      </Form>
    </>
  );
};

export default withRedirect(Login);
