import axios from 'axios';
import classNames from 'classnames';
import is from 'is_js';
import PropTypes from 'prop-types';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { Collapse, Nav, Navbar } from 'reactstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';
import Flex from '../common/Flex';
import Logo from './Logo';
import NavbarTopDropDownMenus from './NavbarTopDropDownMenus';
import NavbarVerticalMenu from './NavbarVerticalMenu';
import ToggleButton from './ToggleButton';

const NavbarVertical = ({ navbarStyle }) => {
  const navBarRef = useRef(null);

  const {
    showBurgerMenu,
    isNavbarVerticalCollapsed,
    setIsNavbarVerticalCollapsed,
    isCombo,
    setShowBurgerMenu,
    setNavbarCollapsed,
    increase
  } = useContext(AppContext);

  const [tables, setTables] = useState([])
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/tables`)
      .then((response) => {
        console.log(response)
        setTables(response.data)
      }).catch((response) => {
        console.log(response)
      })
  }, [increase]);

  const routes = [
    {
      name: 'テーブル管理',
      to: '/',
      exact: true,
      icon: 'copy',
      children: [
        // {
        //   to: '/components/forms',
        //   name: 'テーブル新規作成',
        //   exact: true
        // },
        { to: '/components/upload-forms', name: 'データアップロード' },
      ]
    },
    {
      name: 'テーブルデータ',
      to: '/pages',
      icon: ['fab', 'trello'],
      children: tables.map(table => {
        return {
          to: `/tables/${table.id}/data`,
          name: table.display_name
        }
      })
    },
    // {
    //   name: '分析データ管理',
    //   to: '/',
    //   exact: true,
    //   icon: 'poll',
    //   children: [
    //     {
    //       to: '/components/analysis-forms',
    //       name: 'テーブル結合',
    //       exact: true
    //     },
    //     {
    //       to: '/components/analysis-filter',
    //       name: 'フィルター・並び替え',
    //       exact: true
    //     },
    //   ]
    // },
    // {
    //   name: '分析データ',
    //   to: '/email',
    //   icon: 'chart-pie',
    //   children: [
    //     { to: '/order-return', name: '注文返品状況' },
    //     // { to: '/order-relevant-party', name: '注文地域マネージャー' },
    //     // { to: '/email/compose', name: 'Compose' }
    //   ]
    // }
  ]

  const HTMLClassList = document.getElementsByTagName('html')[0].classList;
  //Control Component did mount and unmounted of hover effect
  if (isNavbarVerticalCollapsed) {
    HTMLClassList.add('navbar-vertical-collapsed');
  }

  useEffect(() => {
    if (is.windows()) {
      HTMLClassList.add('windows');
    }
    if (is.chrome()) {
      HTMLClassList.add('chrome');
    }
    if (is.firefox()) {
      HTMLClassList.add('firefox');
    }
    return () => {
      HTMLClassList.remove('navbar-vertical-collapsed-hover');
    };
  }, [isNavbarVerticalCollapsed, HTMLClassList]);

  //Control mouseEnter event
  let time = null;
  const handleMouseEnter = () => {
    if (isNavbarVerticalCollapsed) {
      time = setTimeout(() => {
        HTMLClassList.add('navbar-vertical-collapsed-hover');
      }, 100);
    }
  };
  return (
    <Navbar
      expand={navbarBreakPoint}
      className={classNames('navbar-vertical navbar-glass', {
        [`navbar-${navbarStyle}`]: navbarStyle !== 'transparent'
      })}
      light
    >
      <Flex align="center">
        <ToggleButton
          isNavbarVerticalCollapsed={isNavbarVerticalCollapsed}
          setIsNavbarVerticalCollapsed={setIsNavbarVerticalCollapsed}
        />
        <Logo at="navbar-vertical" width={100} />
      </Flex>

      <Collapse
        navbar
        isOpen={showBurgerMenu}
        className="scrollbar"
        innerRef={navBarRef}
        onMouseEnter={handleMouseEnter}
        onMouseLeave={() => {
          clearTimeout(time);
          HTMLClassList.remove('navbar-vertical-collapsed-hover');
        }}
      >
        <Nav navbar vertical>
          <NavbarVerticalMenu routes={routes} />
        </Nav>
        <div className="settings px-3 px-xl-0">
          {isCombo && (
            <div className={`d-${topNavbarBreakpoint}-none`}>
              <div className="navbar-vertical-divider">
                <hr className="navbar-vertical-hr my-2" />
              </div>
              <Nav navbar>
                <NavbarTopDropDownMenus setNavbarCollapsed={setNavbarCollapsed} setShowBurgerMenu={setShowBurgerMenu} />
              </Nav>
            </div>
          )}
        </div>
      </Collapse>
    </Navbar>
  );
};

NavbarVertical.protoTypes = {
  navbarStyle: PropTypes.string
};

NavbarVertical.defaultProps = {
  navbarStyle: 'transparent'
};

export default NavbarVertical;
