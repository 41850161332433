import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import axios from 'axios';
import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'reactstrap';
import rocket from '../../assets/img/rocket.png';

const Logout = () => {
  useEffect(() => {
    axios.post(process.env.REACT_APP_API_ENDPOINT + '/logout').then((response) => {
      console.log(response);
    });
  }, []);

  return (
    <div className="text-center">
      <img className="d-block mx-auto mb-4" src={rocket} alt="shield" width={99} />
      <h4>ログアウトしました</h4>
      <br />
      <br />
      <Button tag={Link} color="primary" className="mt-3" to="login">
        <FontAwesomeIcon icon="chevron-left" transform="shrink-4 down-1" className="mr-1" />
        ログインに戻る
      </Button>
    </div>
  );
}

export default Logout;
