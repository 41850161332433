import axios from 'axios';
import PropTypes from 'prop-types';
import React, { useContext, useEffect } from 'react';
import { NavLink } from 'react-router-dom';
import { NavItem } from 'reactstrap';
import { navbarBreakPoint, topNavbarBreakpoint } from '../../config';
import AppContext from '../../context/Context';
import { breakpoints, getPageName } from '../../helpers/utils';
import {
  authenticationRoutes,
  componentRoutes,
  homeRoutes,
  pageRoutes,
  pluginRoutes,
  utilityRoutes
} from '../../routes';
import NavbarDropdown from './NavbarDropdown';
import NavbarDropdownComponents from './NavbarDropdownComponents';

const NavbarTopDropDownMenus = ({ setNavbarCollapsed, setShowBurgerMenu }) => {
  const { isCombo, isTopNav } = useContext(AppContext);

  const components = [componentRoutes, pluginRoutes, utilityRoutes];

  // const [ tables, setTables ] = useState([])

  // const pages = [pageRoutes, calenderRoutes, kanbanRoutes, widgetsRoutes, chatRoutes, emailRoutes, ECommerceRoutes];

  useEffect(() => {
    axios
      .get(`${process.env.REACT_APP_API_ENDPOINT}/api/tables`)
      .then(response => {
        console.log(response);
        // setTables(response.data)
      })
      .catch(response => {
        console.log(response);
      });
  }, []);

  const pages = [
    {
      name: 'テーブルデータ',
      to: '/pages',
      icon: ['fab', 'trello'],
      children: [
        { to: '/order', name: '注文' },
        { to: '/return', name: '返品' },
        { to: '/relevant-party', name: '関係者' }
      ]
    }
  ];

  const handleSetNavbarCollapsed = () => {
    const windowWidth = window.innerWidth;
    isTopNav && !isCombo && windowWidth < breakpoints[topNavbarBreakpoint] && setNavbarCollapsed(false);
    isCombo && windowWidth < breakpoints[navbarBreakPoint] && setShowBurgerMenu(false);
  };
  const isLanding = getPageName('landing');
  return (
    <>
      <NavbarDropdown
        title={homeRoutes.name}
        items={homeRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown title={pageRoutes.name} items={pages} handleSetNavbarCollapsed={handleSetNavbarCollapsed} />
      <NavbarDropdownComponents
        title={componentRoutes.name}
        items={components}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />
      <NavbarDropdown
        title={authenticationRoutes.name}
        items={authenticationRoutes.children}
        handleSetNavbarCollapsed={handleSetNavbarCollapsed}
      />

      {isTopNav && !isLanding && (
        <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/documentation">
            Documentation
          </NavLink>
        </NavItem>
      )}
      {isLanding && (
        <NavItem onClick={handleSetNavbarCollapsed}>
          <NavLink className="nav-link" to="/changelog">
            Changelog
          </NavLink>
        </NavItem>
      )}
    </>
  );
};

NavbarTopDropDownMenus.propTypes = { setNavbarCollapsed: PropTypes.func.isRequired };

export default NavbarTopDropDownMenus;
