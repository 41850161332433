import axios from 'axios';
import React, { createRef, useEffect, useState } from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import paginationFactory, { PaginationProvider } from 'react-bootstrap-table2-paginator';
import { useParams } from 'react-router-dom';
import { Button, Card, CardBody, Col, Row } from 'reactstrap';
import FalconCardHeader from '../common/FalconCardHeader';
import Loader from '../common/Loader';

const Order = () => {

  const { id } = useParams()
  const [columns, setColumns] = useState([])
  const [data, setData] = useState([])
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true)
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/tables/${id}/data`)
      .then((response) => {
        console.log(response)
        // console.log(response.data.shift())
        // console.log(response.data)
        setColumns(response.data.shift())
        setData(response.data)
        setLoading(false)
      }).catch((response) => {
        console.log(response)
      })
  }, [id]);

  const CustomTotal = ({ sizePerPage, totalSize, page, lastIndex }) => (
    <span>
      {(page - 1) * sizePerPage + 1} to {lastIndex > totalSize ? totalSize : lastIndex} of {totalSize} —{' '}
    </span>
  );

  let table = createRef();
  const handleNextPage = ({ page, onPageChange }) => () => {
    onPageChange(page + 1);
  };

  const handlePrevPage = ({ page, onPageChange }) => () => {
    onPageChange(page - 1);
  };

  const options = {
    custom: true,
    sizePerPage: 20,
    totalSize: data.length
  };

  return (
    <>
      <Card className="mb-3">
        {loading ? (
          <Loader />
        ) : (
          <>
            <FalconCardHeader title="テーブルデータ" light={false}>
            </FalconCardHeader>
            <CardBody className="p-0">
              <PaginationProvider pagination={paginationFactory(options)}>
                {({ paginationProps, paginationTableProps }) => {
                  const lastIndex = paginationProps.page * paginationProps.sizePerPage;
                  return (
                    <>
                      <div className="table-responsive">
                        <BootstrapTable
                          ref={table}
                          bootstrap4
                          keyField="id"
                          // data={data.map((item) => {
                          //   let json = {}
                          //   Object.keys(item).forEach(element => {
                          //     if (element === 'id') {
                          //       return
                          //     }
                          //     json[element] = item[element]['S']
                          //   });
                          //   return JSON.parse(JSON.stringify(json))
                          // })}
                          // columns={Object.keys(data[0]).filter((animal) => {
                          //   return animal !== 'id'
                          // }).map((item) => {
                          //   return {
                          //     dataField: item,
                          //     text: item,
                          //     classes: 'border-0 align-middle',
                          //     headerClasses: 'border-0',
                          //     sort: true
                          //   }
                          // })}
                          data={data.map((item) => {
                            let json = {}
                            columns.map((column, index) => {
                              json[column] = item[index]
                            })
                            return JSON.parse(JSON.stringify(json))
                          })}
                          columns={columns.map((item) => {
                            return {
                              dataField: item,
                              text: item,
                              classes: 'border-0 align-middle',
                              headerClasses: 'border-0',
                              sort: true
                            }
                          })}
                          bordered={false}
                          classes="table-dashboard table-sm fs--1 border-bottom border-200 mb-0 table-dashboard-th-nowrap table-dashboard-td-nowrap"
                          rowClasses="btn-reveal-trigger border-top border-200"
                          headerClasses="bg-200 text-900 border-y border-200"
                          {...paginationTableProps}
                        />
                      </div>
                      <Row noGutters className="px-1 py-3">
                        <Col className="pl-3 fs--1">
                          <CustomTotal {...paginationProps} lastIndex={lastIndex} />
                        </Col>
                        <Col xs="auto" className="pr-3">
                          <Button
                            color={paginationProps.page === 1 ? 'light' : 'primary'}
                            size="sm"
                            onClick={handlePrevPage(paginationProps)}
                            disabled={paginationProps.page === 1}
                            className="px-4"
                          >
                            Previous
                          </Button>
                          <Button
                            color={lastIndex >= paginationProps.totalSize ? 'light' : 'primary'}
                            size="sm"
                            onClick={handleNextPage(paginationProps)}
                            disabled={lastIndex >= paginationProps.totalSize}
                            className="px-4 ml-2"
                          >
                            Next
                          </Button>
                        </Col>
                      </Row>
                    </>
                  );
                }}
              </PaginationProvider>

            </CardBody>
          </>
        )}
      </Card>
    </>
  );
};

export default Order;
