import React from 'react';
import { Switch } from 'react-router-dom';
import { Col, Row } from 'reactstrap';
import Section from '../components/common/Section';
import Logo from '../components/navbar/Logo';
import ErrorRoutes from '../routes/ErrorRoutes';


const ErrorLayout = ({ match: { url } }) => (
  <Section className="py-0">
    <Row className="flex-center min-vh-100 py-6">
      <Col sm={11} md={9} lg={7} xl={6} className="col-xxl-5">
        <Logo width={215} className="ml-3"/>
        <Switch>
          <ErrorRoutes />
        </Switch>
      </Col>
    </Row>
  </Section>
);

export default ErrorLayout;
