import React from 'react';
import { Redirect, Route, Switch, withRouter } from 'react-router-dom';
import Login from '../components/auth/Login';
import Logout from '../components/auth/Logout';

const AuthBasicRoutes = () => (
  <Switch>
    <Route path={"/auth/login"} exact component={Login} />
    <Route path={"/auth/logout"} exact component={Logout} />
    <Redirect to="/errors/404" />
  </Switch>
);

export default withRouter(AuthBasicRoutes);
