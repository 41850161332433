import React from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import Forms from '../components/bootstrap-components/Forms';
import UploadForms from '../components/bootstrap-components/UploadForms';
import TableData from '../components/dashboard/Order';

const DashboardRoutes = () => (
  <Switch>
    <Route path="/" exact component={UploadForms} />
    <Route path="/dashboard" exact component={Forms} />
    <Route path="/components/forms" exact component={Forms} />
    <Route path="/components/upload-forms" exact component={UploadForms} />
    <Route path="/tables/:id/data" exact component={TableData} />
    <Redirect to="/errors/404" />
  </Switch>
);

export default DashboardRoutes;
