import axios from 'axios'
import React, { useContext, useState } from 'react'
import { useForm } from "react-hook-form"
import { Button, Card, CardBody, Form, FormGroup, Input, Label, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap'
import FalconCardHeader from '../common/FalconCardHeader'
import AppContext from '../../context/Context';

const Forms = () => {
  const [name, setName] = useState("")
  const [displayName, setDisplayName] = useState("")
  const [modal, setModal] = useState(false)
  const { handleSubmit } = useForm({ reValidateMode: 'onSubmit' })

  const {
    increase,
    setIncrease
  } = useContext(AppContext);

  const onSubmit = (e) => {
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/tables`, {
      name: name,
      display_name: displayName,
    }).then((response) => {
      console.log(response)
      setModal(true)
      setIncrease(increase+1)
    }).catch((response) => {
      console.log(response)
    })
  }

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="テーブル新規作成" light={false} />
        <CardBody className="bg-light">
          <Form onSubmit={handleSubmit(onSubmit)}>
            <FormGroup>
              <Label for="displayName">テーブル日本語名</Label>
              <Input type="text" id="displayName" value={displayName} onChange={(e) => setDisplayName(e.target.value)} />
            </FormGroup>
            <FormGroup>
              <Label for="name">テーブル英名</Label>
              <Input type="text" id="name" value={name} onChange={(e) => setName(e.target.value)} />
            </FormGroup>
            <Button color="primary" block className="mt-3 mb-2">
              Save
            </Button>
            <Modal isOpen={modal}>
              <ModalHeader>登録完了</ModalHeader>
              <ModalBody>
                登録しました
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setModal(false)}>
                  閉じる
                </Button>
              </ModalFooter>
            </Modal>
          </Form>
        </CardBody>
      </Card>
    </>
  )
}

export default Forms
