import axios from 'axios';
import React, { Fragment, useEffect, useState } from 'react';
import Select from "react-select";
import { Button, Card, CardBody, Form, FormGroup, Label, Media, Modal, ModalBody, ModalFooter, ModalHeader } from 'reactstrap';
import cloudUpload from "../../assets/img/icons/cloud-upload.svg";
import FalconCardHeader from '../common/FalconCardHeader';
import FalconDropzone from "../common/FalconDropzone";

const Forms = () => {

  const [modal, setModal] = useState(false)
  const [tables, setTables] = useState([])
  useEffect(() => {
    axios.get(`${process.env.REACT_APP_API_ENDPOINT}/api/tables`)
      .then((response) => {
        console.log(response)
        setTables(response.data)
      }).catch((response) => {
        console.log(response)
      })
  }, []);

  const [uploadTarget, setUploadTarget] = useState({})
  const upload = files => {
    console.log(uploadTarget)
    axios.post(`${process.env.REACT_APP_API_ENDPOINT}/api/tables/${uploadTarget.value}/data`, {
      file: files[0]
    }).then(response => {
      console.log(response)
      setModal(true)
    }).catch((response) => {
      console.log(response)
    })
  }

  return (
    <>
      <Card className="mb-3">
        <FalconCardHeader title="データアップロード" light={false} />
        <CardBody className="bg-light">
          <Form>
            <FormGroup>
              <Label for="exampleFile">アップロードファイル対象テーブル</Label>
              <Select
                value={uploadTarget}
                onChange={value => setUploadTarget(value)}
                classNamePrefix='react-select'
                options={tables.map((table) => {
                  return { value: table.id, label: table.display_name }
                })}
                isSearchable
                placeholder="対象テーブルを選択してください"
              />
            </FormGroup>
            <FormGroup>
              <Label for="exampleFile">アップロードファイル</Label>
              <FalconDropzone
                multiple={false}
                accept=".csv"
                onChange={upload}
                placeholder={
                  <Fragment>
                    <Media className=" fs-0 mx-auto d-inline-flex align-items-center">
                      <img src={cloudUpload} alt="" width={25} className="mr-2" />
                    </Media>
                    <p className="mb-0 w-75 mx-auto text-500">
                      Upload with csv file
                    </p>
                  </Fragment>
                }
              />
            </FormGroup>
            <Modal isOpen={modal}>
              <ModalHeader>登録完了</ModalHeader>
              <ModalBody>
                登録しました
              </ModalBody>
              <ModalFooter>
                <Button onClick={() => setModal(false)}>
                  閉じる
                </Button>
              </ModalFooter>
            </Modal>
            {/* <Button color="primary">Save</Button> */}
          </Form>
        </CardBody>
      </Card>
    </>
  );
}
export default Forms;
